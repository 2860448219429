<template>
  <ion-page id="upload-encyclopedia">
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.upload-encyclopedia.title')" />
    </ion-header>
    <ion-content>
      <ion-list>
        <template v-for="(groupedEntries, identifier) in mappedFilesGroupedByIdentifier" :key="identifier">
          <ion-item-divider class="identifier-group">
            <ion-label>
              {{ identifier }}
              <span v-if="identifier in translationCount.duplicates && translationCount.duplicates[identifier] > 0" class="duplicate-entries-indicator">
                - {{ i18n.$t('tools.upload-encyclopedia.duplicate_entries') }}: <b>{{ translationCount.duplicates[identifier] }}</b>
              </span>
            </ion-label>
          </ion-item-divider>
          <ion-item lines="full" class="type-select">
            <ion-label position="stacked">{{ i18n.$t('tools.upload-encyclopedia.type_select') }}</ion-label>
            <ion-select
              :value="getTypeForIdentifierGroup(identifier)"
              @ionChange="(event) => setTypeForIdentifierGroup(identifier, event.target.value)"
              :okText="i18n.$t('default_interaction.select')"
              :cancelText="i18n.$t('default_interaction.cancel')"
              :placeholder="i18n.$t('default_interaction.select')"
              :interface-options="{ cssClass: 'encyclopedia-type-select' }">
              <ion-select-option v-for="encyclopediaType in availableEncyclopediaTypes" :key="encyclopediaType" :value="encyclopediaType">{{ i18n.$t(`tools.upload-encyclopedia.types.${encyclopediaType}`) }}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item v-for="(file, index) in groupedEntries" :key="index" button detail @click="currentVisibleFileName = file.fullFileName">
            <ion-label class="ion-text-wrap">
              <ion-text>
                <h2 class="file-name">
                  <font-awesome-icon v-if="file.veterinarian" class="veterinarian-indicator" :icon="faUserDoctor" />
                  {{ file.descriptor }}
                </h2>
                <h3 class="missing-media-title" v-if="file.missingFiles.length">{{ i18n.$t('tools.upload-encyclopedia.missing_media') }}: <b>{{ file.missingFiles.length }}</b></h3>
                <h3 class="language-title">{{ i18n.$t('tools.upload-encyclopedia.file_locale') }}:
                  <b v-if="file.locale != null" class="locale-indicator">{{ file.locale }}</b>
                  <b v-else class="missing-locale-indicator">{{ i18n.$t('tools.upload-encyclopedia.missing_locale') }}</b>
                </h3>
              </ion-text>
            </ion-label>
          </ion-item>
        </template>
        <!-- Allows scrolling beyond the list to interact with items without the FAB blocking interaction -->
        <div id="over-scroll"></div>
      </ion-list>
      <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button color="secondary">
          <ion-icon :icon="ellipsisVertical"></ion-icon>
        </ion-fab-button>
        <ion-fab-list side="top">
          <input ref="uploadElement" @change="readMarkdownFiles($event.target.files);$event.target.value = null;" type="file" accept=".md,text/markdown" multiple class="invisible-input"/>
          <ion-fab-button @click="uploadElement.click()" color="secondary">
            <ion-icon :icon="documentOutline"></ion-icon>
          </ion-fab-button>
          <input ref="uploadDirectoryElement" @change="readMarkdownFiles($event.target.files);$event.target.value = null;" type="file" accept=".md,text/markdown,video/*,image/*" multiple directory="" webkitdirectory="" mozdirectory="" class="invisible-input"/>
          <ion-fab-button @click="uploadDirectoryElement.click()" color="secondary">
            <ion-icon :icon="folderOutline"></ion-icon>
          </ion-fab-button>
        </ion-fab-list>
      </ion-fab>
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button :disabled="areAnyEntriesInvalid || currentlyUploading" @click="openUploadConfirmation()" color="success" :class="['upload-button', (currentlyUploading ? 'uploading' : undefined)]">
          <ion-spinner v-if="currentlyUploading"></ion-spinner>
          <ion-icon v-else :icon="cloudUpload"></ion-icon>
        </ion-fab-button>
      </ion-fab>
      <ion-modal
        :is-open="currentVisibleFileProperties != null"
        css-class="modify-encyclopedia-item-dialog"
        @didDismiss="currentVisibleFileName = null">
        <ion-page>
          <ion-header>
            <ion-toolbar>
              <ion-title>{{ (currentVisibleFileProperties != null) ? currentVisibleFileProperties.descriptor : '' }}</ion-title>
              <ion-buttons slot="end">
                <ion-button @click="currentVisibleFileName = null">{{ i18n.$t('default_interaction.close') }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>
          <ion-header>
            <ion-item class="missing-media-list" v-if="currentVisibleFileProperties != null && currentVisibleFileProperties.missingFiles.length" lines="full">
              <ion-label position="stacked" class="missing-media-title">{{ i18n.$t('tools.upload-encyclopedia.missing_media') }}: <b>{{ currentVisibleFileProperties.missingFiles.length }}</b></ion-label>
              <ul>
                <li v-for="(missingFile, missingFileIndex) in currentVisibleFileProperties.missingFiles" :key="missingFileIndex">
                  {{ missingFile }}
                </li>
              </ul>
            </ion-item>
            <ion-item lines="full" class="language-select">
              <ion-label position="stacked">{{ i18n.$t('tools.upload-encyclopedia.language_select') }}</ion-label>
              <ion-select 
                :disabled="availableLanguages == null || availableLanguages.length < 1"
                v-model="currentVisibleFileLocale"
                interface="popover"
                :interface-options="{ cssClass: 'encyclopedia-language-select' }"
                :placeholder="i18n.$t('default_interaction.select')">
                <ion-select-option v-for="language in availableLanguages" :key="language.lang" :value="language.lang">{{ language.lang }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="full" class="identifier-select">
              <ion-label position="stacked">{{ i18n.$t('tools.upload-encyclopedia.identifier_select') }}</ion-label>
              <ion-select
                v-model="currentVisibleFileIdentifier"
                :okText="i18n.$t('default_interaction.select')"
                :cancelText="i18n.$t('default_interaction.cancel')"
                :placeholder="i18n.$t('default_interaction.select')"
                :interface-options="{ cssClass: 'encyclopedia-identifier-select' }">
                <ion-select-option v-for="identifier in availableIdentifiers" :key="identifier" :value="identifier">{{ identifier }}</ion-select-option>
              </ion-select>
            </ion-item>
            <ion-item lines="full" class="localized-descriptor">
              <ion-label position="stacked">{{ i18n.$t('tools.upload-encyclopedia.localized_descriptor') }}</ion-label>
              <ion-input v-model="currentVisibleFileDescriptor"></ion-input>
            </ion-item>
            <ion-item lines="full" class="localized-descriptor">
              <ion-label>{{ i18n.$t('tools.upload-encyclopedia.for_veterinarians') }}</ion-label>
              <ion-checkbox v-model="currentVisibleFileVeterinarian" slot="end"></ion-checkbox>
            </ion-item>
            <!-- TODO In the future save file references with hash. Create a model in API that translates the uploaded files and sets the hashes!-->
            <!-- TODO Make API handle updates with only partial changes. Keep files there in mind. -->
            <!-- TODO Check uploads in API and only allow one per locale. And only one per identifier with POST! -->
          </ion-header>
          <ion-content>
            <MarkdownContent v-if="currentVisibleFileProperties != null" :options="getMarkdownReferencedMediaFiles(currentVisibleFileProperties)" :source="currentVisibleFileProperties.contents"></MarkdownContent>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-item lines="none" id="main-category-select">
          <ion-label position="stacked">{{ i18n.$t('tools.upload-encyclopedia.main_category') }}</ion-label>
          <ion-select v-model="selectedMainCategory" :disabled="localizedMainCategories == null || localizedMainCategories.length < 1" :okText="i18n.$t('default_interaction.select')" :cancelText="i18n.$t('default_interaction.cancel')" :placeholder="i18n.$t('default_interaction.select')">
            <ion-select-option v-for="option in localizedMainCategories" :key="option.id" :value="option.id">{{ option.name }}</ion-select-option>
          </ion-select>
        </ion-item>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent, IonFabList, IonFab, IonFabButton, IonIcon, IonList, IonItem, IonLabel, IonModal, IonToolbar, IonTitle, IonButtons, IonButton, IonText, IonFooter, IonSelect, IonSelectOption, IonItemDivider, IonInput, IonCheckbox, IonSpinner, alertController, toastController } from '@ionic/vue';
import { ref, computed, watch, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';

import MarkdownContent from '@/components/MarkdownContent.vue';

import { folderOutline, documentOutline, cloudUpload, ellipsisVertical, eyeOff, refresh, informationCircleOutline, checkmarkDone } from 'ionicons/icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserDoctor } from '@fortawesome/free-solid-svg-icons';

import { useI18n } from "@/utils/i18n";

import slugify from 'slugify';

import _ from 'lodash';

import { apiErrorToast, localErrorToast } from "@/utils/error";

export default  {
  name: 'UploadReports',
  components: { IonHeader, IonContent, IonPage, IonFabList, IonFab, IonFabButton, IonIcon, IonList, IonItem, IonLabel, IonModal, IonToolbar, IonTitle, IonButtons, IonButton, IonText, IonFooter, IonSelect, IonSelectOption, IonItemDivider, IonInput, IonCheckbox, IonSpinner, MainToolbar, MarkdownContent, FontAwesomeIcon },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const currentlyUploading = ref(false);

    const uploadElement = ref(null);

    const uploadDirectoryElement = ref(null);

    const availableEncyclopediaTypes = ['disease', 'therapy', 'instruction', 'implementation_technical'];

    const MODIFIABLE_PROPERTIES = ['identifier', 'descriptor', 'veterinarian', 'locale'];

    //Saves properties like identifier and descriptor that were set by the user from the default
    const modifiedFileProperties = ref({});
    const modifiedIdentifierTypes = ref({});

    const markdownFiles = ref({});

    const mediaFiles = ref({});

    const currentVisibleFileName = ref(null);

    const selectedMainCategory = ref(null);

    const availableLanguages = computed(() => {
      return i18n.$getAvailableLanguages();
    });

    //Used to detect the locale by the filename. If it ends with this regex (e.g. "_EN"), replace it and set the locale
    const availableLanguageRegexes = computed(() => {
      return _.compact(_.map(availableLanguages.value, (language) => {
        if (language != null && language.lang != null) {
          return {
            locale: language.lang,
            expression: new RegExp(`_${language.lang}$`, 'i')
          }
        }
      }))
    });

    const getLocalizedMainCategory = computed(() => function(mainCategoryId){
      let categoryNames = store.getters['reports/getMainCategoryNamesById'](mainCategoryId);
      if (categoryNames) {
        return categoryNames[i18n.locale.value];
      } else {
        return null;
      }
    });

    const localizedMainCategories = computed(() => {
      let categories = store.getters['reports/getMainCategories'];
      let language = i18n.locale.value;
      if (categories != null) {
        return _.sortBy(Object.values(categories).map(category => {
          return {
            id: category.id,
            name: (category.name != null && (language in category.name)) ? category.name[language] : null,
            order: category.order
          };
        }), 'order');
      } else {
        return null;
      }
    });

    const getTypeForIdentifierGroup = computed(() => {
      return function(identifier) {
        return _.get(modifiedIdentifierTypes.value, [identifier]);
      }
    });

    const setTypeForIdentifierGroup = function(identifier, newType) {
      //Set as new object to trigger computed
      modifiedIdentifierTypes.value = {
        ...modifiedIdentifierTypes.value,
        [identifier]: newType
      }
    }

    const markdownFileRegex = /(!\[.*?\])\((.*?)\)/g;
    const lastPartOfPathRegex = /[^/]*\/?([^/]*)$/; //Takes the last directory and file or just file if no directory specified
    const lastPartOfPathRegexGlobal = new RegExp(lastPartOfPathRegex, 'g');

    const readMarkdownFiles = function(files){
      let filePromises = [];
      let mediaPromises = [];

      for (let file of files){
        try {
          if (file.type.includes('image') || file.type.includes('video')) {
            mediaPromises.push(new Promise((resolve, reject) => {
              try {
                resolve({
                  name: file.name,
                  fileObject: file,
                  fileURL: URL.createObjectURL(file),
                  filePath: (file.webkitRelativePath != null) ? _.first(file.webkitRelativePath.match(lastPartOfPathRegex)) : file.name,
                  type: (file.type.includes('image') ? 'image' : (file.type.includes('video') ? 'video' : 'media'))
                })
              } catch (error) {
                reject(error);
              }
            }));
          } else if (file.type.includes('text')) {
            //Remove file extension
            let fileNameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');

            let reader = new FileReader(); 
            filePromises.push(new Promise((resolve, reject) => { 
              reader.onload = function(){ 
                try {
                  let fileURLs = [];
                  //Add each file URL to the file array
                  reader.result.matchAll(markdownFileRegex).forEach((markdownFileMatch) => { //Find all required URLs for media files with the format ![urlName](urlPath)
                    let urlPath = _.get(markdownFileMatch, '[2]'); //Second capturing group is urlPath, only continue if it exists
                    if (urlPath == null) return;
                    let filename = _.get([...urlPath.matchAll(lastPartOfPathRegexGlobal)], '[0][1]'); //Get the first match and then capturing group 1
                    fileURLs.push({ path: urlPath, name: filename }); //Save the first capturing group as the filename, if it exists
                  });

                  resolve({
                    fullFileName: file.name,
                    name: fileNameWithoutExtension,
                    contents: reader.result,
                    fileURLs,
                    type: 'markdown'
                  });
                } catch (error) {
                  reject(error);
                }
              } 
            }));
            
            reader.readAsText(file);
          }
        } catch (error) {
          console.error(error);
          localErrorToast(i18n, i18n.$t('tools.upload-encyclopedia.load_error'), error.message);
        }
      }

      Promise.all(filePromises)
      .then((newFiles) => {
        //Add markdown files by the key to not upload duplicates!
        markdownFiles.value = _.assign(markdownFiles.value, _.keyBy(newFiles, 'fullFileName'));
      })
      .catch((error) => {
        console.error(error);
        localErrorToast(i18n, i18n.$t('tools.upload-encyclopedia.load_error'), error.message);
      });

      Promise.all(mediaPromises)
      .then((newFiles) => {
        //Add all files with they keys being either their relative path or filename as fallback
        _.assign(mediaFiles.value, _.keyBy(newFiles, (newFile) => (newFile.filePath != null && newFile.filePath.length > 0) ? newFile.filePath : newFile.name));
      })
      .catch((error) => {
        console.error(error);
        localErrorToast(i18n, i18n.$t('tools.upload-encyclopedia.load_error'), error.message);
      });
    }

    const releaseMediaFiles = function() {
      if (mediaFiles.value != null && mediaFiles.value.length > 0) {
        _.forEach(mediaFiles.value, (mediaObject) => {
          if (mediaObject != null && mediaObject.fileURL != null) URL.revokeObjectURL(mediaObject.fileURL);
        });
      }
    }

    const resetForm = function(){
      releaseMediaFiles();
      markdownFiles.value = {};
      mediaFiles.value = {};
      selectedMainCategory.value = null;
      currentVisibleFileName.value = null;
      modifiedFileProperties.value = {};
      modifiedIdentifierTypes.value = {};
    }

    const filesWithIdentifiers = ref({});
    watch(markdownFiles,
          (newFiles) => {
      let fileProcessingPromises = _.map(newFiles, (file) => {
        //Return an async function that gets called immediately to create a promise
        return (async () => {
          let fileName = file.name;
          let fileLocale;
          //Check for each locale, if the fileName ends with it. If it does, replace and set as the locale
          _.forEach(availableLanguageRegexes.value, ({locale, expression}) => {
            //Try to replace. If it is found it is replaced and the function invoked
            fileName = fileName.replace(expression, (match) => {
              if (match != null && match.length > 0) {
                fileLocale = locale;
              }
              return '';
            });
          });

          let identifier = slugify(fileName, {
              replacement: '_',
              lower: true, // convert to lower case
              strict: false, // strip special characters
              locale: 'de',
              trim: false
          });

          let fileProperties = {
            fullFileName: file.fullFileName,
            descriptor: fileName,
            locale: fileLocale,
            identifier,
            contents: file.contents,
            visuals: file.fileURLs
          };

          return [file.fullFileName, fileProperties];
        })();
      });

      //Map all files to get shortIdentifier and set the ordering properties - Promise.all for generating the identifiers asynchronously
      Promise.all(fileProcessingPromises)
      .then((processedFiles) => filesWithIdentifiers.value = _.fromPairs(processedFiles))
      .catch((error) => {
        //On error notify user and just return an empty array
        console.error(error);
        localErrorToast(i18n, i18n.$t('tools.upload-encyclopedia.load_error'), error.message);
        return [];
      });
    }, { deep: true });

    const mappedFiles = computed(() => {
      //TODO Set parameters from previous version, if any files exist!?
      if (filesWithIdentifiers.value != null && Object.keys(filesWithIdentifiers.value).length > 0) {
        return _.mapValues(filesWithIdentifiers.value, (file) => {
          let mappedFile = {
            ...file,
            visuals: _.keyBy(_.map(file.visuals, (visualPaths) => {
              return {
                ...visualPaths,
                file: _.get(mediaFiles.value, [visualPaths.path], _.get(mediaFiles.value, [visualPaths.name])) //Try to get via relative path or as a fallback via filename in case the file loader only got the filenames!
              }
            }), 'path')
          };

          mappedFile.missingFiles = _.map(_.filter(mappedFile.visuals, (visualFile) => visualFile.file == null), (missingFile) => (missingFile.path || missingFile.name));

          //All by the user modified properties that get picked here, will be applied to the mapped file if they are set, but can be null too!
          if (file.fullFileName != null && modifiedFileProperties.value != null && file.fullFileName in modifiedFileProperties.value) {
            let modifiedProperties = _.pick(modifiedFileProperties.value[file.fullFileName], MODIFIABLE_PROPERTIES);

            _.forEach(modifiedProperties, (property, propertyKey) => {
              if (property !== undefined) {
                mappedFile[propertyKey] = property;
              }
            });
          }

          //Type is set for all entries under one identifier group. Apply to all of them
          mappedFile['type'] = getTypeForIdentifierGroup.value(mappedFile.identifier);
          //mainCategory is applied for all
          mappedFile['main_category'] = selectedMainCategory.value || undefined;

          return mappedFile;
        });
      }

      return [];
    });

    const mappedFilesGroupedByIdentifier = computed(() => {
      return _.groupBy(mappedFiles.value, 'identifier');
    });

    const availableIdentifiers = computed(() => {
      if (mappedFilesGroupedByIdentifier.value != null) return Object.keys(mappedFilesGroupedByIdentifier.value);
      return [];
    });

    const translationCount = computed(() => {
      let counts = _.mapValues(mappedFilesGroupedByIdentifier.value, (filesInGroup) => {
        let total = filesInGroup.length;
        let uniqueEntries = _.uniqBy(filesInGroup, (fileProperties) => { //Per locale and vet/non-vet only 1 entry is allowed!
          return `${_.toUpper(fileProperties.locale)}${(fileProperties.veterinarian) ? '_veterinarian' : ''}`; //Normalize an identifying string for those properties
        });

        return {
          total,
          duplicates: total - (uniqueEntries.length)
        }
      });

      return {
        groups: _.mapValues(counts, 'total'),
        duplicates: _.mapValues(counts, 'duplicates'),
        total: _.sum(_.map(counts, 'total')),
        totalDuplicates: _.sum(_.map(counts, 'duplicates'))
      }
    });

    const currentVisibleFileProperties = computed({
      get: () => {
        if (currentVisibleFileName.value != null && mappedFiles.value != null) {
          return _.get(mappedFiles.value, [currentVisibleFileName.value]);
        }

        return null;
      },
      set: (newProperties) => {
        if (currentVisibleFileName.value != null) {
          //Set the properties at the current selected fullFileName, if it is valid
          let fullFileName = currentVisibleFileName.value;
          //Either load the properties that are already set or take an empty object
          let existingModifiedProperties = modifiedFileProperties.value[fullFileName] || {};
          //Set all existing values again and overwrite with new ones. Create a shallow copy for re-setting so it always triggers computed
          modifiedFileProperties.value = {
            ...modifiedFileProperties.value,
            [fullFileName]: {
              ...existingModifiedProperties,
              ...newProperties
            }
          }
        }
      }
    });

    const currentVisibleFileIdentifier = computed({
      get: () => _.get(currentVisibleFileProperties.value, 'identifier'),
      set: (newIdentifier) => {
        currentVisibleFileProperties.value = {
          'identifier': newIdentifier
        };
      }
    });

    const currentVisibleFileDescriptor = computed({
      get: () => _.get(currentVisibleFileProperties.value, 'descriptor'),
      set: (newDescriptor) => {
        currentVisibleFileProperties.value = {
          'descriptor': newDescriptor
        };
      }
    });

    const currentVisibleFileVeterinarian = computed({
      get: () => _.get(currentVisibleFileProperties.value, 'veterinarian'),
      set: (newStatus) => {
        currentVisibleFileProperties.value = {
          'veterinarian': newStatus
        };
      }
    });

    const currentVisibleFileLocale = computed({
      get: () => _.get(currentVisibleFileProperties.value, 'locale'),
      set: (newLocale) => {
        currentVisibleFileProperties.value = {
          'locale': newLocale
        };
      }
    });

    const getMarkdownReferencedMediaFiles = computed(() => {
      return function(fileProperties) {
        let options = {}
        options['modifyToken'] = function(token/*, env*/) {
          if (token.type === 'image' || token.type === 'video') {
            if (token.attrObj.src !== null) {
              //Try to get the file with the specified index or an empty string to not load anything
              token.attrObj.src = _.get(fileProperties, ['visuals', token.attrObj.src, 'file', 'fileURL'], '');
            }
          }
        }
        return options;
      }
    });

    const areAnyEntriesInvalid = computed(() => {
      if (translationCount.value.total <= 0 || translationCount.value.totalDuplicates > 0) return true;

      //Check if any file is not complete yet (not all properties to sort it set!)
      return _.some(mappedFiles.value, (fileProperties) => {
        return (
          fileProperties.identifier == null ||
          fileProperties.locale == null ||
          fileProperties.descriptor == null ||
          fileProperties.type == null ||
          fileProperties['main_category'] == null ||
          (fileProperties.missingFiles != null && fileProperties.missingFiles.length > 0)
        );
      });
    });

    const uploadEncyclopediaEntries = async function(){
      currentlyUploading.value = true;

      //Merge all different versions (locales and for vets and non-vets) together into one per identifier
      let mergedEntryTranslations = _.map(mappedFilesGroupedByIdentifier.value, (groupedFiles, identifier) => {
        //Save all translations by their locale, to only get one per locale
        let translations = {};
        let entry = {
          identifier,
          descriptor: identifier //Fallback
        }
        let visuals = {};

        _.forEach(groupedFiles, (translationFile) => {
          if (translationFile.locale != null && translationFile.descriptor != null && translationFile.type != null && translationFile['main_category'] != null && (translationFile.missingFiles == null || translationFile.missingFiles.length <= 0)) {
            let veterinarianAddition = (translationFile.veterinarian) ? '_veterinarian' : '';

            let currentLocale = _.toLower(translationFile.locale);

            _.setWith(translations, [currentLocale, 'locale', 'locale'], currentLocale, Object); //Locale is a nested component with 2 levels
            _.setWith(translations, [currentLocale, 'type'], translationFile.type, Object);
            _.setWith(translations, [currentLocale, 'main_category'], translationFile['main_category'], Object);
            _.setWith(translations, [currentLocale, `descriptor${veterinarianAddition}`], translationFile.descriptor, Object);
            _.setWith(translations, [currentLocale, `text${veterinarianAddition}`], translationFile.contents, Object);
            _.assign(visuals, translationFile.visuals); //Add visuals, duplicates are overwritte by their keys
          }
        });

        if (Object.keys(translations).length <= 0) return null;

        visuals = Object.values(visuals); //Convert to array
        //Create an object that maps paths to their indizes in the array
        let visualPathMapping = {};
        _.forEach(visuals, (visualFile, visualIndex) => {
          visualPathMapping[visualFile.path] = visualIndex;
        });

        //TODO Try to shuffle the array before mapping and check if upload still works okay!

        //Take either the german version or the first version as the valid descriptor, type and main_category for this identifier. Prefer vet over non-vet entries!
        let bestMatch = _.get(translations, ['de']); //Try to get german
        //Otherwise look for the first descriptor. If a vet_descriptor is found in one return that one
        if (bestMatch == null) {
          for (let properties of Object.values(translations)) {
            if (properties['descriptor_veterinarian'] != null) {
              bestMatch = properties;
              break; //Stop early, no better match can be found
            }
            //Save the first match for now and look for a better one
            if (bestMatch == null && properties['descriptor'] != null) {
              bestMatch = properties;
            }
          }
        }

        let newDescriptor = _.get(bestMatch, ['descriptor_veterinarian'], _.get(bestMatch, ['descriptor'])) //Prefer vet over non-vet descriptor
        if (newDescriptor != null) entry.descriptor = newDescriptor; //Set only if not null, because of fallback
        entry.type = _.get(bestMatch, ['type']);
        entry['main_category'] = _.get(bestMatch, ['main_category']);

        //Replace all file URLs with the index in the mapping and cleanup unused properties
        translations = _.mapValues(translations, (translation) => {
          const textProperties = ['text_veterinarian', 'text'];
          let newTranslation = {..._.omit(translation, _.concat(textProperties, ['type', 'main_category']))};

          _.forEach(textProperties, (textProperty) => {
            let oldContents = _.get(translation, textProperty);
            if (oldContents == null) return;
            newTranslation[textProperty] = oldContents.replace(markdownFileRegex, (match, urlNamePart, urlPath) => { //Convert all URLs for images and videos with the format ![urlName](urlPath)
              let newIndex = _.get(visualPathMapping, [urlPath], ''); //Empty string as fallback
              return `${urlNamePart}(${newIndex})`; //TODO If GET parameters get added, we need to parse them for uploads and add them. Also need to be processed in the API when returnning URLs!
            });
          });

          return newTranslation;
        });

        entry.translations = Object.values(translations);

        return { entry, visuals: _.compact(_.map(visuals, (visualFile) => _.get(visualFile, ['file', 'fileObject']))) }; //Return just the file instances
      });

      let creationPromises = [];

      //Only upload valid ones that are left
      for (let entryObject of _.compact(mergedEntryTranslations)){
        //TODO Once PUT is possible add it here too!
        creationPromises.push(
          store.dispatch('encyclopedia/createNewEncyclopediaEntry', { entry: entryObject.entry, files: { visualizations: entryObject.visuals } })
        );
      }

      Promise.all(creationPromises).then(() => {
        toastController.create(
          {
            message: i18n.$t('tools.upload-encyclopedia.uploaded'),
            position: 'top',
            duration: 5000,
            color: 'success',
            buttons: [
              {
                text: i18n.$t('default_interaction.close'),
                role: 'cancel'
              }
            ]
          }
        ).then((toast) => {
          toast.present();
          resetForm();
        });
      })
      .catch((error) => {
        apiErrorToast(i18n, error);
      })
      .finally(() => {
        store.dispatch('encyclopedia/fetchEncyclopediaIndex'); //Update index, because something must have changed during the update
        currentlyUploading.value = false;
      });
    }

    const openUploadConfirmation = async function(){
        const alert = await alertController
        .create({
          cssClass: 'upload-confirmation-alert',
          header: i18n.$t('tools.upload-encyclopedia.upload-confirmation.title'),
          message:  i18n.$t('tools.upload-encyclopedia.upload-confirmation.message.before') + 
                    '<b>' + availableIdentifiers.value.length + '</b>' + 
                    i18n.$t('tools.upload-encyclopedia.upload-confirmation.message.after') + 
                    ' <br/><br/> ' + i18n.$t('tools.upload-encyclopedia.upload-confirmation.message.translations_before') + 
                    '<b>' + translationCount.value.total + '</b>' +
                    i18n.$t('tools.upload-encyclopedia.upload-confirmation.message.translations_after') + 
                    ' <br/><br/> ' + i18n.$t('tools.upload-encyclopedia.upload-confirmation.message.category') + 
                    '<b>' + getLocalizedMainCategory.value(selectedMainCategory.value) + '</b>',
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.upload-encyclopedia.upload-confirmation.upload'),
              cssClass: 'upload-confirmation-okay',
              handler: () => {
                uploadEncyclopediaEntries();
              },
            },
          ],
        });
      return alert.present();
    }

    onBeforeUnmount(() => {
      releaseMediaFiles();
    });

    return {
      i18n,
      currentlyUploading,
      availableEncyclopediaTypes,
      availableLanguages,
      uploadElement,
      uploadDirectoryElement,
      localizedMainCategories,
      getTypeForIdentifierGroup,
      setTypeForIdentifierGroup,
      currentVisibleFileName,
      mappedFiles,
      mappedFilesGroupedByIdentifier,
      availableIdentifiers,
      currentVisibleFileProperties,
      currentVisibleFileIdentifier,
      currentVisibleFileDescriptor,
      currentVisibleFileVeterinarian,
      currentVisibleFileLocale,
      translationCount,
      areAnyEntriesInvalid,
      readMarkdownFiles,
      getMarkdownReferencedMediaFiles,
      openUploadConfirmation,
      selectedMainCategory,
      folderOutline,
      documentOutline,
      cloudUpload,
      ellipsisVertical,
      eyeOff,
      refresh,
      informationCircleOutline,
      checkmarkDone,
      faUserDoctor
    };
  }
}
</script>

<style>
.upload-confirmation-okay, .hiding-dialog-okay {
  color: var(--ion-color-success-shade)!important;
}

/* Allow for longer texts in select and indent every line but the first using a negative offset on the first line in a wrapped text */
.encyclopedia-identifier-select .alert-radio-label, .encyclopedia-identifier-select .alert-checkbox-label, .encyclopedia-type-select .alert-radio-label, .encyclopedia-type-select .alert-checkbox-label {
  margin-left: 6px;
  white-space: normal!important;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-indent: -6px;
}

/* Fix that the radio buttons are displayed, even when wrapping more than two lines */
.encyclopedia-identifier-select .select-interface-option, .encyclopedia-type-select .select-interface-option {
  height: auto;
  contain: content;
}

.modify-encyclopedia-item-dialog {
  --min-width: 90%;
  --min-height: 90%;
}

.modify-encyclopedia-item-dialog ion-content > * {
  margin: 20px;
}

.modify-encyclopedia-item-dialog .missing-media-title, #upload-encyclopedia .missing-media-title {
  color: var(--ion-color-danger);
}

.encyclopedia-language-select {
  --width: 8em;
}

.encyclopedia-language-select .select-interface-option {
  text-transform: uppercase;
}
</style>

<style scoped>
ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-list {
  padding: 0px;
}

.highlight-changed {
  color: var(--ion-color-warning-shade);
}

#over-scroll {
  width: 100%;
  height: 100px;
}

.invisible-input {
  visibility: hidden;
  display: none;
}

.file-name {
  padding-top: 5px;
}

.veterinarian-indicator {
  color: var(--ion-color-primary);
  margin-right: 5px;
}

ion-fab-button.fab-button-disabled {
  opacity: 0.5;
}

.missing-media-list li {
  font-size: 0.8 em;
}

.identifier-group {
  color: var(--ion-color-primary-text);
  font-weight: bold;
}

.missing-locale-indicator, .duplicate-entries-indicator {
  color: var(--ion-color-danger);
}

.locale-indicator, .language-select ion-select {
  text-transform: uppercase;
}

.language-select ion-select::part(placeholder), .type-select ion-select::part(placeholder), #main-category-select ion-select::part(placeholder) {
  text-transform: none;
  color: var(--ion-color-danger);
  opacity: 1!important;
}

.type-select, .missing-media-list {
  --border-width: 0px 0px 3px 0px;
}

.upload-button.uploading {
  opacity: 1!important;
}

.upload-button ion-spinner {
  color: var(--ion-color-contrast);
}

</style>
